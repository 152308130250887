@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
	html {
		font-family: 'Kanit', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg); 
	color: white;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}

.button,
.button * {
  box-sizing: border-box;
}
.button {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.button-base {
  background: var(--white, #ffffff);
  border-radius: 8px;
  border-style: solid;
  border-color: var(--error-300, #fda29b);
  border-width: 1px;
  padding: 8px 14px 8px 14px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  box-shadow: var(
    --shadow-xs-box-shadow,
    0px 1px 2px 0px rgba(16, 24, 40, 0.05)
  );
  overflow: hidden;
}
.text {
  color: var(--error-700, #b42318);
  text-align: left;
  font-family: 'Kanit', sans-serif !important;
  font-size: 11px;
  /* line-height: 110%; */
  font-weight: 700;
  position: relative;
}
